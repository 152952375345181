<template>
  <div
    v-if="raServices.length"
    class="ra-service-container"
  >
    <h4 class="ra-details">
      Your Registered Agent Details
    </h4>

    <div
      v-for="service in raServices"
      :key="service.id"
      class="services-by-jurisdiction"
    >
      <h4 class="jurisdiction">
        {{ service.jurisdiction.state_province_region }}
      </h4>
      <hr>
      <div class="services-container">
        <div class="ra-address">
          <p class="mb-0">
            {{ getRaName(service) }}
          </p>
          <p class="mb-0">
            {{ getRaAddressLines(service) }}
          </p>
          <p>
            {{ getRaAddressCityStateZip(service) }}
          </p>
        </div>
        <div class="ra-status">
          <p class="mb-0">
            <span class="start-end-date">Start Date: </span>
            {{ getDate(service.start) }}
          </p>
          <p>
            <span class="start-end-date">End Date: </span>
            {{ getDate(service.stop) }}
          </p>
        </div>
      </div>
      <div
        v-if="hasRaAdditionalDetails(service)"
        class="additional-details-container"
        v-html="getRaAdditionalDetails(service)"
      />
    </div>
  </div>
</template>

<script>
import * as DOMPurify from 'dompurify'

export default {
  name: "RegisteredAgentServiceOrderConfirmation",
  props: {
    services: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    raServices() {
      return this.services.filter(service => service.type === 'registered-agent')
    },
  },
  methods: {
    getDate(timestamp) {
      return new Date(timestamp*1000).toLocaleDateString()
    },
    getRaName(service) {
      return service.registered_agent_name
    },
    getRaAddressLines(service) {
      const raAddress = service.registered_agent_address

      return raAddress.line2 ?
        raAddress.line1 + ' ' + raAddress.line2 :
        raAddress.line1
    },
    getRaAddressCityStateZip(service) {
      const raAddress = service.registered_agent_address

      return raAddress.city + ', ' +
        raAddress.state_province_region + ' ' +
        raAddress.zip_postal_code
    },
    getRaAdditionalDetails(service) {
      return DOMPurify.sanitize(service.registered_agent_additional_details)
    },
    hasRaAdditionalDetails(service) {
      return service.registered_agent_additional_details != null
    },
  },
}
</script>

<style lang="scss" scoped>
$black: #231F20;

h4,
p {
  color: $black;
}

h4 {
  font-size: 1.25em;
  font-weight: 600;
}

p {
  font-size: 1.0em;
  font-weight: 400;
}

hr {
  width: 100%;
}

.ra-service-container {
  width: 100%;

  h4.ra-details {
    font-weight: 700;
  }

  .services-by-jurisdiction {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;

    .jurisdiction {
      margin-bottom: 0;
    }

    .services-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .start-end-date {
        font-weight: 700;
      }

      .ra-address {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .ra-status {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }

    .additional-details-container {
      padding: 1.0em 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  h4 {
    font-size: 1.125em;
  }

  .ra-service-container {

    .services-by-jurisdiction {

      .services-container {
        display: flex;
        flex-flow: column-reverse nowrap;
        justify-content: center;
        align-items: flex-start;

        .ra-address,
        .ra-status {
          justify-content: center;
          align-items: flex-start;
        }
      }
    }
  }
}

@media print {
  .ra-service-container {

    hr:last-child {
      display: none;
    }
  }
}
</style>
