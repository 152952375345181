var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.raServices.length
    ? _c(
        "div",
        { staticClass: "ra-service-container" },
        [
          _c("h4", { staticClass: "ra-details" }, [
            _vm._v("\n    Your Registered Agent Details\n  "),
          ]),
          _vm._l(_vm.raServices, function (service) {
            return _c(
              "div",
              { key: service.id, staticClass: "services-by-jurisdiction" },
              [
                _c("h4", { staticClass: "jurisdiction" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(service.jurisdiction.state_province_region) +
                      "\n    "
                  ),
                ]),
                _c("hr"),
                _c("div", { staticClass: "services-container" }, [
                  _c("div", { staticClass: "ra-address" }, [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getRaName(service)) +
                          "\n        "
                      ),
                    ]),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getRaAddressLines(service)) +
                          "\n        "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getRaAddressCityStateZip(service)) +
                          "\n        "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "ra-status" }, [
                    _c("p", { staticClass: "mb-0" }, [
                      _c("span", { staticClass: "start-end-date" }, [
                        _vm._v("Start Date: "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getDate(service.start)) +
                          "\n        "
                      ),
                    ]),
                    _c("p", [
                      _c("span", { staticClass: "start-end-date" }, [
                        _vm._v("End Date: "),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getDate(service.stop)) +
                          "\n        "
                      ),
                    ]),
                  ]),
                ]),
                _vm.hasRaAdditionalDetails(service)
                  ? _c("div", {
                      staticClass: "additional-details-container",
                      domProps: {
                        innerHTML: _vm._s(_vm.getRaAdditionalDetails(service)),
                      },
                    })
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }